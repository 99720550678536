<template>
  <div id="international-top-up">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'InternationalTopUp'">
      <template v-slot:right-end>
        <div class="flex justify-center ml-4" style="place-items: end;">
          <vs-button class="mt-5" @click="checkStatusDialog">{{ $t('CheckStatus') }}</vs-button>
        </div>
      </template>
    </breadcrumb-base>


    <div class="mx-6 w-full">
      <div class="vs-row flex w-full">
        <div class="md:w-1/4">

          <vx-card class="flex px-2 py-4">
            <div class="">
              <div class="vs-col w-full">
                <vs-select
                  autocomplete
                  class="w-full"
                  :label="$t('Country')"
                  v-model="selectedCountry"
                  @input="onCountryChanged"
                >
                  <vs-select-item :key="index" :value="country" :text="getCountryNameCode(country)"
                                  v-for="(country,index) in countries"/>
                </vs-select>
              </div>
              <div class="vs-col w-full">
                <vs-input
                  v-validate="'required|min:5'" @input="onCountryOrMobileChanged"
                  validate-on="change" class="is-label-placeholder w-full" icon-pack="feather" icon="icon-phone"
                  :label="$t('MobileNo')" :placeholder="$t('MobileNo')" v-model="mobileNo"/>
              </div>
              <div class="vs-col w-full">
                <vs-input
                  v-validate="'required|min:5'" @input="onCountryOrMobileChanged"
                  validate-on="change" class="is-label-placeholder w-full" icon-pack="feather" icon="icon-phone"
                  :label="$t('ConfirmMobileNumber')" :placeholder="$t('MobileNo')" v-model="confirmMobileNo"/>
              </div>
              <div class="vs-col py-6  w-full">
                <vs-button class=" w-full" color="secondary"
                           @click="mobileLookUp()">{{ $t('GetProviders') }}</vs-button>
              </div>
              <!--              <div class="vs-col w-full">-->
              <!--                <vs-select-->
              <!--                  autocomplete-->
              <!--                  class="w-full"-->
              <!--                  :label="$t('Region')"-->
              <!--                  v-model="selectedRegion"-->
              <!--                  v-if="regions.length > 0"-->
              <!--                  @input="onProviderChanged"-->
              <!--                >-->
              <!--                  <vs-select-item :key="index" :value="region"-->
              <!--                                  :text="region.region_name" v-for="(region,index) in regions"  />-->
              <!--                </vs-select>-->
              <!--              </div>-->
              <div class="vs-col w-full">
                <vs-select
                  autocomplete
                  class=" w-full"
                  :label="$t('Provider')"
                  v-model="selectedProvider"
                  v-if="providers.length > 0"
                  @input="onProviderChanged"
                >
                  <vs-select-item :key="index" :value="provider" :text="provider.name" v-for="(provider,index) in providers"  />
                </vs-select>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vs-col md:w-3/4 px-4" v-if="productsData">

          <div class="flex flex-row space-x-4 my-6">
            <!--            <vs-input-->
            <!--              class=" is-label-placeholder" icon-pack="feather" icon="icon-search"-->
            <!--              :placeholder="$t('Search')" v-model="searchProductText"/>-->
            <vs-select
              autocomplete
              v-model="selectedSortPrice"
              :label="$t('Sort')"
              class="">
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                              v-for="(item,index) in sortPrices"/>
            </vs-select>
            <vs-select v-model="selectedPlanType"
                       autocomplete
                       @input="fetchProducts"
                       :label="$t('SelectPlanType')"
                       class="">
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                              v-for="(item,index) in dropdownPlanType"/>
            </vs-select>
          </div>

          <div class="mt-5 flex flex-wrap w-full" >
            <div class="w-full lg:w-1/3 pr-5 pb-5" v-for="(product, index) in products">
              <vx-card class="rounded-md" @click="createRecharge(product)">
                <div class="vx-row vs-align-center">
                  <h1 class="mx-3 item-card-text">{{ product.description }}</h1>
                </div>
                <div class="py-2"></div>
                <div class="vx-row vs-align-center">
                  <vs-chip color="#24c1a0">
                    <h1 class="mx-3 item-card-amount">{{ product.prices.retail.amount  | germanNumberFormat }}</h1>
                  </vs-chip>
                  <vs-spacer></vs-spacer>
                  <h3 class="mx-3 item-card-amount">{{ product.destination.amount | generalNumberFormat(currency = product.destination.unit) }} </h3>
                </div>
              </vx-card>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div>

      <vs-sidebar  v-if="showRechargePopUp" click-not-close position-right parent="body" default-index="1" color="primary"
                   class="cash-cards-side-bar custom-card-sidebar items-no-padding" spacer v-model="showRechargePopUp">
        <!--      <vx-modal v-if="showConfigsActive" @close="closedShowConfigs" component-class="modal-container-7">-->
        <div class="mt-6 flex items-center justify-between px-6">
          <vs-avatar color="danger" @click="closedShowRecharge()" size="large" icon="clear">
          </vs-avatar>
          <!--          <feather-icon icon="XIcon"  class="cursor-pointer"></feather-icon>-->
        </div>

        <div >
          <table class="table-auto">
            <tbody>
            <tr>
              <td class="border px-4 py-2 modal-text-title">{{ $t('MobileNo') }}</td>
              <td class="border px-4 py-2 modal-text-content">{{ mobileNo }}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2 modal-text-title">{{ $t('Product') }}</td>
              <td class="border px-4 py-2 modal-text-content">{{ selectedProduct.description }}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2 modal-text-title">{{ $t('Amount') }}
                ({{ selectedProduct.prices.retail.unit }})
              </td>
              <td class="border px-4 py-2 modal-text-content">
                {{ selectedProduct.prices.retail.amount | germanNumberFormat }}
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 modal-text-title">{{ $t('AmountReceive') }}
                ({{ selectedProduct.destination.unit }})
              </td>
              <td class="border px-4 py-2 modal-text-content">
                {{ selectedProduct.destination.amount | generalNumberFormat(currency = selectedProduct.destination.unit) }}
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 modal-text-title">{{ $t('Discount') }}</td>
              <td class="border px-4 py-2 modal-text-content">{{ discount }}</td>
            </tr>
            <!--            <tr>-->
            <!--              <td class="border px-4 py-2 modal-text-title">{{ $t('Profit') }}</td>-->
            <!--              <td class="border px-4 py-2 modal-text-content">{{ calculateProfit(selectedProduct.maximum_send_value, discount) | germanNumberFormat }}</td>-->
            <!--            </tr>-->
            </tbody>
          </table>
          <div class="py-3"></div>
          <div class="vs-row flex justify-center">
            <vs-button size="large" class="ml-4  mr-4" color="danger" @click="closedTriggerShowRecharge()">{{
                $t('Close')
              }}
            </vs-button>
            <vs-button size="large" class="ml-2" color="secondary" @click="doRecharge()">{{ $t('Recharge') }}</vs-button>
          </div>
        </div>
      </vs-sidebar>
    </div>

    <div>
      <vx-modal v-if="transactionData" component-class="modal-container-fit">
        <template slot="header"></template>
        <template slot="body">

          <div v-if="transactionData">
            <receipt-print
              v-on:close="closePrintCardModal()"
              ref="printCard"
              :print-report-type="2" :purchase-data="transactionData"></receipt-print>
          </div>
        </template>
      </vx-modal>
    </div>
    <div>
      <vx-modal v-if="showCheckTransactionDialog" component-class="w-1/3">
        <template slot="header"></template>
        <template slot="body">
          <intl-status-check v-on:close="closeStatusDialog" />
        </template>
      </vx-modal>
    </div>
  </div>

</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'
import IntlStatusCheck from "@/views/components/IntlStatusCheck";

export default {
  name: 'InternationalTopUp',
  components: {
    IntlStatusCheck,
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      mobileNo: null,
      confirmMobileNo: null,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'International TopUp', i18n: 'InternationalTopUp', active: true},
      ],
      selectedSortPrice: "e1",
      sortPrices: [
        {
          text: 'Euro: Low to High',
          value: 'e1',
        },
        {
          text: 'Euro: High to Low',
          value: 'e2',
        },
        {
          text: 'Original Price: Low to High',
          value: 'o1',
        },
        {
          text: 'Original Price: High to Low',
          value: 'o2',
        },
      ],
      selectedPlanType: "TALK_TIME",
      dropdownPlanType: [
        {
          text: 'ALL',
          value: 'ALL',
        },
        {
          text: 'Talk Time',
          value: 'TALK_TIME',
        },
        {
          text: 'Data',
          value: 'DATA',
        }
      ],
      selectedCountry: null,
      selectedProvider: null,
      providersData: null,
      productsData: null,
      selectedProduct: null,
      searchProductText: '',
      showRechargePopUp: false,
      transactionData: null,
      dtCreateResponse: null,
      showCheckTransactionDialog: false,
    }
  },
  computed: {
    countries() {
      return this.$store.state.internationalTopUp.countries
    },
    discount() {
      return this.activeUserInfo.dt_one_discount || 0
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    providers() {
      return this.$store.state.internationalTopUp.providers
    },
    products() {
      if (!this.productsData) return []
      let d = this.productsData.products_list;
      if (this.searchProductText != '') {
        d = this.productsData.products_list.filter((c) => c.description.toLowerCase().startsWith(this.searchProductText.toLowerCase()))
      }
      if(this.selectedSortPrice != "") {
        return d.sort((a, b) => {
          if(this.selectedSortPrice == "e1") {
            if (a.prices.retail.amount < b.prices.retail.amount) return -1
            if (a.prices.retail.amount > b.prices.retail.amount) return 1
            return 0
          }
          if(this.selectedSortPrice == "e2") {
            if (a.prices.retail.amount > b.prices.retail.amount) return -1
            if (a.prices.retail.amount < b.prices.retail.amount) return 1
            return 0
          }
          if(this.selectedSortPrice == "o1") {
            if (a.destination.amount < b.destination.amount) return -1
            if (a.destination.amount > b.destination.amount) return 1
            return 0
          }
          if(this.selectedSortPrice == "o2") {
            if (a.destination.amount > b.destination.amount) return -1
            if (a.destination.amount < b.destination.amount) return 1
            return 0
          }
        })
      }
      return d;
    },
  },
  methods: {
    checkStatusDialog() {
      this.showCheckTransactionDialog = true;
    },
    closeStatusDialog() {
      this.showCheckTransactionDialog = false;
    },
    calculateProfit(amount, commission) {
      return (Number(amount) * (Number(commission) / 100)).toFixed(2)
    },
    closePrintCardModal() {
      this.showPurchaseDataModal = false
      this.iniProvider()
      this.clearAllData()
      this.transactionData = null
      this.closedShowRecharge()
      this.fetchProducts()
    },
    getCountryNameCode(country) {
      return `(${country.dial_code})  ${country.name}`
    },
    getCountryCode(country) {
      return country.dial_code;
    },
    closedTriggerShowRecharge() {
      this.closedShowRecharge()
      this.cancelCurrentTransaction()
    },
    closedShowRecharge() {
      this.selectedProduct = null
      this.showRechargePopUp = false
    },
    showRechargePop(product) {
      this.selectedProduct = product
      this.showRechargePopUp = true
    },
    clearAllData() {
      this.$store.dispatch('internationalTopUp/clearStateData')
      this.mobileNo = ''
      this.selectedRegion = null
      this.confirmMobileNo = ''
    },
    iniProvider() {
      this.providersData = null
      this.selectedProvider = null
      this.$store.dispatch('internationalTopUp/clearStateData')
      this.initProduct()
    },
    initProduct() {
      this.providersData = null
      this.productsData = null
      this.selectedProductIndex = null
      this.searchProductText = ''
    },
    onCountryChanged() {
      this.iniProvider()
      this.initProduct()
    },
    onCountryOrMobileChanged() {
      this.iniProvider()
    },
    onProviderChanged() {
      this.initProduct()
      this.fetchProducts()
    },
    mobileLookUp() {
      if (!this.selectedCountry) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select country',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.mobileNo.length < 5) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('FillMobileError'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.mobileNo != this.confirmMobileNo) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('MobileNotMatched'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      const mobile = this.getCountryCode(this.selectedCountry) + this.mobileNo

      this.$vs.loading()
      this.$store.dispatch('internationalTopUp/mobileLookUp', mobile)
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type != 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            this.providersData = {};
            this.providersData.providers = data.response
            if (this.providersData.providers.length > 0) {
              // this.regions.forEach((region) => {
              //   if (region.region_code == this.providersData.providers[0].region_id) {
              //     this.selectedRegion = region
              //     this.fetchProviders()
              //   }
              // })
              this.selectedProvider = this.providersData.providers[0]
              this.fetchProducts()
            }
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchProducts() {
      if (!this.selectedCountry) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select country',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (!this.selectedProvider) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select provider',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      const payload = {
        country_code: this.selectedProvider.country.iso_code,
        provider_code: this.selectedProvider.id,
      }

      if(this.selectedPlanType) {
        payload['plan_type'] = this.selectedPlanType
      }

      this.$vs.loading()
      this.$store.dispatch('internationalTopUp/fetchProducts', payload)
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type != 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            if(data.response.errors) {
              this.$vs.notify({
                position: 'top-center',
                title: 'Error',
                text: data.response.errors[0].message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
              })
              return
            }
            this.productsData = {}
            this.productsData.products_list = data.response
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    createRecharge(product) {
      const mobile = this.getCountryCode(this.selectedCountry) + this.mobileNo
      const payload = {
        product_id: product.id,
        mobile_number: mobile,
        configuration: '3'
      }

      this.$vs.loading()

      this.$store.dispatch('internationalTopUp/createConfirmRejectRecharge', payload)
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type != 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            if(data.status == 10000) {
              this.dtCreateResponse = data
              this.showRechargePop(product);
            } else {
              this.$vs.notify({
                position: 'top-center',
                title: 'Error',
                text: data.message || 'Unknown error',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
              })
            }
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })

    },
    cancelCurrentTransaction() {
      const payload = {
        dt_one_id: this.dtCreateResponse.dt_one_id,
        configuration: '5'
      }

      this.dtCreateResponse = null

      this.$vs.loading()
      this.$store.dispatch('internationalTopUp/createConfirmRejectRecharge', payload)
        .then((data) => {
          this.$vs.loading.close()
        })
    },
    doRecharge() {
      this.showRechargePopUp = false

      const payload = {
        dt_one_id: this.dtCreateResponse.dt_one_id,
        configuration: '6'
      }

      this.$vs.loading()
      this.$store.dispatch('internationalTopUp/createConfirmRejectRecharge', payload)
        .then((data) => {
          this.$vs.loading.close()
          console.log(data)
          if (data.message_type != 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            if(data.status == 20000) {
              this.transactionData = data.response
              this.checkPrintCardPop()
            } else {
              this.$vs.notify({
                position: 'top-center',
                title: 'Error',
                text: data.message || 'Unknown error',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
              })
            }
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
  },
  created() {
    this.$vs.loading()
    this.$store.dispatch('internationalTopUp/fetchCountries')
      .then((data) => {
        this.$vs.loading.close()
        this.errorFetching = false
      })
      .catch((error) => {
        console.error(error)
        this.$vs.loading.close()
        this.errorFetching = true
        let msg = ''
        try {
          msg = error.response.data.message
        } catch (err) {
          msg = ''
        }
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
}
</script>

<style lang="scss">
#international-top-up {
  .item-card-text {
    font-size: 1.4rem;
    font-weight: 500;
    word-break: break-all;
  }
  .item-card-amount {
    font-size: 1.1rem;
    font-weight: normal;
  }

}
.table-auto {
  padding: 24px 50px;
  margin: auto;
}
.modal-text-title {
  font-size: 1.5rem !important;
  font-weight: 800;
}
.modal-text-content {
  font-size: 1.5rem !important;
}
</style>
